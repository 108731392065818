export default function cleanUpDocExcelFileTypes(filemime) {
    // create object of file types and filename extensions to show on the front end
    // the 3 main file types are PDF, XLSX, and DOCX 
    // we will show the filemime if it is not one of these types and can add add it to the object if a new one is added 
    const mimeTypeMap = {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DOCX",
      "application/pdf": "PDF"
    };
  
    return mimeTypeMap[filemime.toLowerCase()] || filemime.toLowerCase();
  }
  